import React from "react";
import Arogya1Logo from "../assets/images/aarogya1-logo.png";
import DownloadIOS from "../assets/images/download-ios.svg";
import DownloadPlay from "../assets/images/download-play.svg";
import { Link } from "react-router-dom";

import SM1 from "../assets/images/sm-linkedin.svg";
import SM2 from "../assets/images/sm-facebook.svg";
import SM3 from "../assets/images/sm-youtube.svg";
import SM4 from "../assets/images/sm-instagram.svg";
import SM5 from "../assets/images/sm-twitter.svg";

const Footer = (props) => {
  const { menu } = props;
  const { data } = menu || [];

  return (
    <footer>
      <div className="footer-bg pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 footer-l">
              <Link to="/">
                <img className="footer-logo" src={Arogya1Logo} alt="" />
              </Link>
              <p className="footer-desc text-white mt-4">{props?.info}</p>
              <div className="sm-icons mt-4">
                <Link
                  to="https://www.linkedin.com/company/aarogyaone"
                  target="_blank"
                >
                  <img src={SM1} alt="" />
                </Link>

                <Link to="https://www.facebook.com/aarogyaone" target="_blank">
                  <img src={SM2} alt="Facebook" />
                </Link>

                <Link to="https://www.youtube.com/@AarogyaOne" target="_blank">
                  <img src={SM3} alt="" />
                </Link>

                <Link to="https://www.instagram.com/aarogyaone" target="_blank">
                  <img src={SM4} alt="" />
                </Link>

                <Link to="https://www.twitter.com/@aarogyaone" target="_blank">
                  <img src={SM5} alt="" />
                </Link>
              </div>
            </div>
            <div className="col-lg-4 footer-c">
              <div className="h-100 ps-3">
                <div className="navbar p-0 m-0">
                  <ul className="list-unstyled p-0 m-0 row">
                    <li className="col-lg-6">
                      <Link to="/">{data && data[0]?.title}</Link>
                    </li>
                    <li className="col-lg-6">
                      <Link to="/features">{data && data[6]?.title}</Link>
                    </li>
                    <li className="col-lg-6">
                      <Link to="/about-us">{data && data[1]?.title}</Link>
                    </li>
                    <li className="col-lg-6">
                      <Link to="/abdm">{data && data[3]?.title}</Link>
                    </li>
                    <li className="col-lg-6">
                      <Link to="/contact-us">{data && data[4]?.title}</Link>
                    </li>
                    <li className="col-lg-6">
                      <Link to="https://www.aarogya.one/blog/" target="_blank">
                        {data && data[2]?.title}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 footer-r">
              <div className="footer-email mb-3">
                <span className="material-symbols-outlined">mail</span>
                <Link to="mailto:support@aarogya.one">support@aarogya.one</Link>
              </div>

              <div className="footer-call mb-3">
                <span className="material-symbols-outlined">call</span> +91
                87990 72687
              </div>

              <div className="footer-down-btns mt-4">
                <Link
                  className="btn p-0"
                  target="_blank"
                  to="https://play.google.com/store/apps/details?id=com.jdeanzhealthtech.aarogyaone&pcampaignid=web_share/"
                >
                  <img src={DownloadPlay} alt="" />
                </Link>
                <Link
                  className="btn p-0"
                  target="_blank"
                  to="https://apps.apple.com/in/app/aarogyaone/id6471126953/"
                >
                  <img src={DownloadIOS} alt="" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="copyrights">
        <div className="container">
          <p className="mb-0 text-white small pt-4 pb-4 text-center">
            {props?.copyrights}
            <Link
              to="/privacy-policy"
              className="ms-3 text-decoration-none text-white"
            >
              {data && data[7]?.title}
            </Link>
            <Link
              to="/terms-conditions"
              className="ms-3 text-decoration-none text-white"
            >
              {data && data[8]?.title}
            </Link>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
