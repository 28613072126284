import React from "react";
import DownloadIOS from "../assets/images/download-ios.svg";
import DownloadPlay from "../assets/images/download-play.svg";
import ABDM from "../assets/images/ABDM.png";
import NHA from "../assets/images/NHA.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import { Link } from "react-router-dom";

const HomeSlider = (props) => {
  const { banner } = props;
  const { data } = banner || [];

  return (
    <div className="home-slider">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 d-flex align-items-center">
            <div className="w-100">
              <Swiper
                slidesPerView={1}
                centeredSlides={false}
                slidesPerGroupSkip={1}
                grabCursor={true}
                breakpoints={{
                  769: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                  },
                }}
                pagination={{
                  clickable: true,
                }}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                modules={[Pagination, Autoplay]}
                className="mySwiper"
                loop={true}
              >
                {data &&
                  data.map((x, key) => (
                    <SwiperSlide key={key}>
                      <div className="home-slider-content">
                        <h3 className="mb-4">{x?.subtitle}</h3>
                        <h2>{x?.title1}</h2>
                        <h2>{x?.title2}</h2>
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>
              <div className="text-center mt-4">
                  <img className="abdmnha-logo-1" src={ABDM} />
                  <img className="abdmnha-logo-2" src={NHA} />
              </div>
              <div className="text-center mt-4">
                  <h5>ABDM Compliant - NHA Approved</h5>
              </div>
              <div className="text-center mt-4">
                <Link
                  className="btn"
                  target="_blank"
                  to="https://play.google.com/store/apps/details?id=com.jdeanzhealthtech.aarogyaone&pcampaignid=web_share/"
                >
                  <img src={DownloadPlay} />
                </Link>
                <Link
                  className="btn"
                  target="_blank"
                  to="https://apps.apple.com/in/app/aarogyaone/id6471126953/"
                >
                  <img src={DownloadIOS} />
                </Link>
              </div>
             </div>

            <div className="home-slider-height"></div>
          </div>
          <div className="col-lg-6 mobile-hide">
            <div
              style={{
                position: "fixed",
                height: "calc(100vh)",
                display: "flex",
                transform: "skew(-16deg, 4deg)",
              }}
            >
              <div style={{ height: "100%" }}>
                <div className="sliding-background-1"></div>
              </div>
              <div style={{ height: "100%", position: "relative" }}>
                <div className="sliding-background-2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="banner-curve-l"></div>
      <div className="banner-curve-r1"></div>
      <div className="banner-curve-r2"></div>
      <div className="banner-curve-r3"></div>
    </div>
  );
};

export default HomeSlider;
