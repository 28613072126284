import React, { useState, useEffect } from "react";
import HomeFeatures from "../components/home-features";
import HomeWhoWeAre from "../components/home-whoweare";
import HomeBenefits from "../components/home-benefits";
import HomeSlider from "../components/home-slider";
import HomeHowItWorks from "../components/home-howitworks";
import HomeBlogs from "../components/home-blogs";
import HomeTestimonials from "../components/home-testimonials";
import HomeVblogs from "../components/home-vbologs";
import { Helmet } from "react-helmet";
import fetchData from "../services/requester";

const Home = (props) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  const getData = () => {
    let reqURL = "home.json";
    fetchData(reqURL, null, (res) => {
      setData(res);
      setLoading(false);
    });
  };

  useEffect(() => {
    getData();
    window.scrollTo(0, -1);
  }, []);

  return (
    <div className="home">
      <Helmet>
        <title>{data?.title}</title>
        <meta name="keywords" content={data?.keywords} />
        <meta name="description" content={data?.desscription} />
      </Helmet>

      <HomeSlider {...data} handleDownloads={props.handleDownloads} />
      {!loading && (
        <div style={{ background: "#fff", position: "relative" }}>
          <HomeFeatures {...data} />
          <HomeHowItWorks {...data} />
          <HomeBenefits {...data} />
          <HomeWhoWeAre {...data} />
          <HomeTestimonials {...data} />
          <HomeBlogs {...data} />
          <HomeVblogs {...data} />
        </div>
      )}
    </div>
  );
};

export default Home;
