import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Banner from "../assets/images/banner-terms-conditions.jpg";
import parse from "html-react-parser";
import { Helmet } from "react-helmet";
import fetchData from "../services/requester";

const TermsConditions = () => {
  const [headers, setHeaders] = useState();
  const [data, setData] = useState([]);

  const getData = () => {
    let reqURL = "terms-conditions.json";
    fetchData(reqURL, null, (res) => {
      setHeaders(res || "");
      setData(res.data || []);
    });
  };

  useEffect(() => {
    getData();
    window.scrollTo(0, -1);
  }, []);

  return (
    <div className="page-inside">
      <Helmet>
        <title>{headers?.title}</title>
        <meta name="keywords" content={headers?.keywords} />
        <meta name="description" content={headers?.desscription} />
      </Helmet>

      <div className="page-inside-banner">
        <div className="container">
          <h1 className="text-center">{headers?.header}</h1>
        </div>
        <img src={Banner} alt={headers?.header} />
      </div>

      <div className="container">
        <div className="text-secondary">
          {data &&
            data.map((x, key) => (
              <React.Fragment key={key}>
                {x?.title && <h4>{x?.title}</h4>}

                {x?.paragraphs &&
                  x?.paragraphs.map((y, key) => <p key={key}>{parse(y)}</p>)}

                {x?.lists && (
                  <ul>
                    {x?.lists.map((z, key) => (
                      <li key={key}>{parse(z)}</li>
                    ))}
                  </ul>
                )}

                {x?.numlists && (
                  <ol>
                    {x?.numlists.map((w, key) => (
                      <li key={key}>{parse(w)}</li>
                    ))}
                  </ol>
                )}

                {x?.note && <p>{parse(x?.note)}</p>}

                {x?.support && (
                  <p className="d-flex align-items-center">
                    <span className="material-symbols-outlined text-primary me-3">
                      phone
                    </span>
                    <span>{x?.support?.calltitle}</span>
                    <span className="text-primary ms-3 fw-bold">
                      {x?.support?.number}
                    </span>
                  </p>
                )}

                {x?.support && (
                  <p className="d-flex align-items-center">
                    <span className="material-symbols-outlined text-primary me-3">
                      mail
                    </span>
                    <span>{x?.support?.emailtitle}</span>
                    <Link
                      to={"mailto:" + x?.support?.email}
                      className="text-primary ms-3 fw-bold"
                    >
                      {x?.support?.email}
                    </Link>
                  </p>
                )}
              </React.Fragment>
            ))}
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
