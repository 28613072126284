import React from "react";

const HomeBenefits = ({ benefits }) => {
  const { data } = benefits || [];

  return (
    <div className="home-benefits">
      <div className="container">
        <h2 className="text-center">{benefits?.title}</h2>
        <h5 className="mb-5">{benefits?.subtitle}</h5>

        <div className="row pt-3">
          <div className="col-lg-4 d-flex align-items-center col-left">
            <ul className="list-unstyled d-flex justify-content-end">
              {data &&
                data.map(
                  (x, key) =>
                    key < 4 && (
                      <li
                        key={key}
                        className="d-flex justify-content-end align-items-center"
                      >
                        <h4>{x?.title}</h4>
                        <span className="material-symbols-outlined ms-4">
                          {x?.icon}
                        </span>
                      </li>
                    )
                )}
            </ul>
          </div>

          <div className="col-lg-4 text-center home-benefits-img">
            <img
              src={benefits?.image}
              alt={benefits?.title}
              title={benefits?.title}
            />
          </div>

          <div className="col-lg-4 d-flex align-items-center col-right">
            <ul className="list-unstyled d-flex justify-content-start">
              {data &&
                data.map(
                  (x, key) =>
                    key > 3 && (
                      <li
                        key={key}
                        className="d-flex justify-content-start align-items-center"
                      >
                        <span className="material-symbols-outlined me-4">
                          {x?.icon}
                        </span>
                        <h4>{x?.title}</h4>
                      </li>
                    )
                )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeBenefits;
