//const url = window.location.origin.replace(":3000", "");
const url = "https://contactapp.aarogya.one";

const fetchDataExt = (reqOBJ, callback, reqMethod) => {
  let path = url + "/inquiry-management/default.php";

  return fetch(path, {
    method: reqMethod || "GET",
    body: reqOBJ ? JSON.stringify(reqOBJ) : null,
  })
    .then((res) => res.json())
    .then((data) => {
      callback(data);
    });
};

export default fetchDataExt;
