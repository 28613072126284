import React from "react";
import { Link } from "react-router-dom";
import Menu from "./menu";
import Arogya1Logo from "../assets/images/aarogya1-logo.png";

const Header = (props) => {
  return (
    <header className="pt-2 pb-2">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 d-flex align-items-center">
            <Link to="/" className="text-black text-decoration-none">
              <img
                src={Arogya1Logo}
                alt="ArogyaOne"
                style={{ height: "24px" }}
              />
            </Link>
          </div>
          <div className="col-lg-9 d-flex justify-content-end">
            <Menu handleDownloads={props.handleDownloads} {...props} />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
