import React, { useState, useEffect } from "react";
import Banner from "../assets/images/banner-privacy-policy.jpg";
import parse from "html-react-parser";
import { Helmet } from "react-helmet";
import fetchData from "../services/requester";

const PrivacyPolicy = () => {
  const [headers, setHeaders] = useState();
  const [data, setData] = useState([]);

  const getData = () => {
    let reqURL = "privacy-policy.json";
    fetchData(reqURL, null, (res) => {
      setHeaders(res || "");
      setData(res.data || []);
    });
  };

  useEffect(() => {
    getData();
    window.scrollTo(0, -1);
  }, []);

  return (
    <div className="page-inside">
      <Helmet>
        <title>{headers?.title}</title>
        <meta name="keywords" content={headers?.keywords} />
        <meta name="description" content={headers?.desscription} />
      </Helmet>

      <div className="page-inside-banner">
        <div className="container">
          <h1 className="text-center">{headers?.header}</h1>
        </div>
        <img src={Banner} alt={headers?.header} />
      </div>

      <div className="container">
        <div className="text-secondary">
          {data &&
            data.map((x, key) => (
              <React.Fragment key={key}>
                {x?.title && <h4>{x?.title}</h4>}

                {x?.paragraphs &&
                  x?.paragraphs.map((y, key) => <p key={key}>{parse(y)}</p>)}

                {x?.lists && (
                  <ul>
                    {x?.lists.map((z, key) => (
                      <li key={key}>{parse(z)}</li>
                    ))}
                  </ul>
                )}

                {x?.numlists && (
                  <ol>
                    {x?.numlists.map((w, key) => (
                      <li key={key}>{parse(w)}</li>
                    ))}
                  </ol>
                )}

                {x?.note && <p>{parse(x?.note)}</p>}
              </React.Fragment>
            ))}
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
