import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import TagManager from "react-gtm-module";
import fetchData from "./services/requester";
import Header from "./components/header";
import Home from "./screens/home";
import Footer from "./components/footer";
import PrivacyPolicy from "./screens/privacy-policy";
import TermsConditions from "./screens/terms-conditions";
import Features from "./screens/features";
import GDPR from "./components/gdpr";
import AboutUs from "./screens/about-us";
import ContactUs from "./screens/contact-us";
import Abdm from "./screens/abdm";
import Downloads from "./components/download";
import Inquiries from "./screens/inquiries";

const App = () => {
  const [data, setData] = useState();
  const [isDownload, setDownloads] = useState(false);
  const [langKey, setLangKey] = useState(1000);

  const getData = () => {
    let reqURL = "general.json";
    fetchData(reqURL, null, (res) => {
      setData(res);
    });
  };

  const handleLanguage = () => {
    localStorage.setItem(
      "lang",
      localStorage.getItem("lang") !== "hindi" ? "hindi" : ""
    );
    getData();
    setLangKey(langKey + 1);
  };

  const tagManagerArgs = {
    gtmId: "GTM-5JLSPZM3",
  };

  TagManager.initialize(tagManagerArgs);

  useEffect(() => {
    getData();
  }, []);

  return (
    <React.Fragment>
      <Header
        key={langKey * 1000}
        handleDownloads={() => setDownloads(!isDownload)}
        {...data}
      />
      <Routes key={langKey}>
        <Route
          key={1}
          path="/"
          element={<Home handleDownloads={() => setDownloads(!isDownload)} />}
        />
        <Route key={2} path="/features" element={<Features />} />
        <Route key={3} path="/about-us" element={<AboutUs />} />
        <Route key={4} path="/abdm" element={<Abdm />} />
        <Route
          key={5}
          path="/contact-us"
          element={
            <ContactUs handleDownloads={() => setDownloads(!isDownload)} />
          }
        />
        <Route
          key={6}
          path="/thank-you"
          element={
            <ContactUs handleDownloads={() => setDownloads(!isDownload)} />
          }
        />
        <Route key={7} path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route key={8} path="/terms-conditions" element={<TermsConditions />} />
        <Route key={8} path="/inquiries" element={<Inquiries />} />
      </Routes>
      <Footer
        key={langKey * 10000}
        {...data}
        handleDownloads={() => setDownloads(!isDownload)}
        handleLanguage={() => handleLanguage()}
      />
      <GDPR {...data} />
      {isDownload && (
        <Downloads
          {...data}
          handleDownloads={() => setDownloads(!isDownload)}
        />
      )}
      <button className="lang-btn" onClick={() => handleLanguage()}>
        {localStorage.getItem("lang") !== "hindi" ? "हिं" : "En"}
      </button>
    </React.Fragment>
  );
};

export default App;
