import React, { useState } from "react";
import AbhaIcon from "../assets/images/abha-icon.png";
import { Link } from "react-router-dom";

const HomeFeatures = ({ features }) => {
  const [active, setActive] = useState("tab1");
  const { data } = features || [];

  return (
    <div className="home-features">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="home-features-icons">
              <div className="row">
                <div className="col-lg-6 d-flex align-items-center">
                  <div>
                    <div
                      className={
                        "feature-box text-center p-4 mb-4 " +
                        (active === "tab1" ? "active" : "")
                      }
                      onClick={() => setActive("tab1")}
                    >
                      <div>
                        <span className="features-icon features-icon-c3 material-symbols-outlined">
                          {data[0]?.icon}
                        </span>
                        <h5>{data[0]?.title}</h5>
                      </div>
                    </div>
                    <div
                      className={
                        "feature-box text-center p-4 mb-4 " +
                        (active === "tab2" ? "active" : "")
                      }
                      onClick={() => setActive("tab2")}
                    >
                      <div>
                        <span className="features-icon features-icon-c1 material-symbols-outlined">
                          <img
                            src={AbhaIcon}
                            style={{ height: "64px" }}
                            alt=""
                          />
                        </span>
                        <h5>{data[1]?.title}</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 d-flex align-items-center">
                  <div>
                    <div
                      className={
                        "feature-box text-center p-4 mb-4 " +
                        (active === "tab3" ? "active" : "")
                      }
                      onClick={() => setActive("tab3")}
                    >
                      <div>
                        <span className="features-icon features-icon-c1 material-symbols-outlined">
                          {data[2]?.icon}
                        </span>
                        <h5>{data[2]?.title}</h5>
                      </div>
                    </div>
                    <div
                      className={
                        "feature-box text-center p-4 mb-4 " +
                        (active === "tab4" ? "active" : "")
                      }
                      onClick={() => setActive("tab4")}
                    >
                      <div>
                        <span className="features-icon features-icon-c2 material-symbols-outlined">
                          {data[3]?.icon}
                        </span>
                        <h5>{data[3]?.title}</h5>
                      </div>
                    </div>
                    <div
                      className={
                        "feature-box text-center p-4 mb-4 " +
                        (active === "tab5" ? "active" : "")
                      }
                      onClick={() => setActive("tab5")}
                    >
                      <div>
                        <span className="features-icon features-icon-c3 material-symbols-outlined">
                          {data[4]?.icon}
                        </span>
                        <h5>{data[4]?.title}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-8">
            <div className="home-features-content">
              <h2 className="mb-5 mt-4">{features?.title}</h2>

              <div className="home-features-icons home-features-icons-mobile d-none">
                <div className="home-features-icons-mobile-wrap">
                  <div
                    className={
                      "feature-box text-center p-4 mb-4 " +
                      (active === "tab1" ? "active" : "")
                    }
                    onClick={() => setActive("tab1")}
                  >
                    <div>
                      <span className="features-icon features-icon-c3 material-symbols-outlined">
                        upload_file
                      </span>
                      <h5>Upload</h5>
                    </div>
                  </div>
                  <div
                    className={
                      "feature-box text-center p-4 mb-4 " +
                      (active === "tab2" ? "active" : "")
                    }
                    onClick={() => setActive("tab2")}
                  >
                    <div>
                      <span className="features-icon features-icon-c1 material-symbols-outlined">
                        <img src={AbhaIcon} style={{ height: "64px" }} alt="" />
                      </span>
                      <h5>ABHA</h5>
                    </div>
                  </div>
                  <div
                    className={
                      "feature-box text-center p-4 mb-4 " +
                      (active === "tab3" ? "active" : "")
                    }
                    onClick={() => setActive("tab3")}
                  >
                    <div>
                      <span className="features-icon features-icon-c1 material-symbols-outlined">
                        share
                      </span>
                      <h5>Share</h5>
                    </div>
                  </div>
                  <div
                    className={
                      "feature-box text-center p-4 mb-4 " +
                      (active === "tab4" ? "active" : "")
                    }
                    onClick={() => setActive("tab4")}
                  >
                    <div>
                      <span className="features-icon features-icon-c2 material-symbols-outlined">
                        group
                      </span>
                      <h5>Manage</h5>
                    </div>
                  </div>
                  <div
                    className={
                      "feature-box text-center p-4 mb-4 " +
                      (active === "tab5" ? "active" : "")
                    }
                    onClick={() => setActive("tab5")}
                  >
                    <div>
                      <span className="features-icon features-icon-c3 material-symbols-outlined">
                        event_available
                      </span>
                      <h5>Anytime</h5>
                    </div>
                  </div>
                </div>
              </div>

              {data &&
                data.map(
                  (x, key) =>
                    active === "tab" + x.index && (
                      <div key={key}>
                        <h3 className="mb-5">{data[key]?.title}</h3>
                        <ul className="list-unstyled text-secondary">
                          {data[key].data.map((y, key) => (
                            <li key={key}>
                              <span className="check-bullet material-icons-sharp">
                                check_circle
                              </span>
                              {y}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )
                )}

              <Link
                to="/features"
                className="btn btn-outline-secondary mt-4 ps-4 pr-3"
              >
                {features?.more}{" "}
                <span className="material-symbols-outlined">chevron_right</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeFeatures;
