import React, { useState } from "react";
import Plus from "../assets/images/plus.png";
import Minus from "../assets/images/minus.png";

const HomeHowItWorks = ({ howitworks }) => {
  const [active, setActive] = useState("tab1");

  const { data } = howitworks || [];

  return (
    <div className="home-howitworks">
      <div className="container">
        <h2 className="text-center d-block">{howitworks?.title}</h2>
        <h5 className="text-center mb-4">{howitworks?.subtitle}</h5>

        <div className="row pt-3">
          <div className="col-lg-4 text-center">
            {data &&
              data.map(
                (x, key) =>
                  active === "tab" + x?.index && (
                    <img
                      className="mobile-screen mt-4"
                      key={key}
                      src={x?.image}
                      alt={x?.title}
                      title={x?.title}
                    />
                  )
              )}
          </div>

          <div className="col-lg-8">
            {data &&
              data.map((x, key) => (
                <div key={key}>
                  <h3
                    onClick={() => setActive("tab" + x?.index)}
                    className={active === "tab" + x?.index ? "active" : ""}
                  >
                    <img
                      src={active === "tab" + x?.index ? Minus : Plus}
                      alt=""
                    />
                    {x?.title}
                  </h3>

                  {active === "tab" + x?.index && (
                    <div>
                      {x?.desc && <h4>{x?.desc}</h4>}
                      <ul className="mt-4">
                        {x?.data.map((y, key) => (
                          <li key={key}>{y}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
        <div className="mb-2">&nbsp;</div>
      </div>
    </div>
  );
};

export default HomeHowItWorks;
