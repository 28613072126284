const url = window.location.origin;

const fetchData = (reqURL, reqOBJ, callback, reqMethod) => {
  let lang = localStorage.getItem("lang") !== "hindi" ? "/" : "-hindi/";
  let path = url + "/content" + lang + reqURL;

  return fetch(path, {
    method: reqMethod || "GET",
    headers: {
      "Content-type": "application/json",
    },
    body: reqOBJ ? JSON.stringify(reqOBJ) : null,
  })
    .then((res) => res.json())
    .then((data) => {
      callback(data);
    });
};

export default fetchData;
