import React from "react";

const HomeWhoWeAre = ({ whoweare }) => {
  const { data } = whoweare || [];
  return (
    <div className="home-whoweare">
      <div className="container">
        <div>
          <div className="row">
            {data &&
              data.map((x, key) => (
                <div
                  key={key}
                  className={key < 3 ? "col-lg-4 mb-4" : "col-lg-3 mb-4"}
                >
                  <div className="p-4 letter-box">
                    <span className="letter">{x?.icon}</span>
                    <h3 className="mt-3 text-white">{x?.title}</h3>
                    <p className="text-white">{x?.desc}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeWhoWeAre;
