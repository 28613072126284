import React, { useState, useEffect } from "react";
import Banner from "../assets/images/banner-contact.png";
import fetchDataExt from "../services/requester-ext";

const Inquiries = () => {
  const [data, setData] = useState([]);

  const getInquiries = () => {
    let obj = {
      path: "get-inquiries",
    };
    fetchDataExt(
      obj,
      (data) => {
        setData(data?.response || []);
      },
      "POST"
    );
  };

  useEffect(() => {
    getInquiries();
  }, []);

  return (
    <div className="page-inside">
      <div className="page-inside-banner">
        <div className="container">
          <h1 className="text-center">Inquiries</h1>
        </div>
        <img src={Banner} alt="" />
      </div>

      <div className="container">
        <table className="table table-bordered1">
          <thead>
            <tr>
              <th>Date</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Type</th>
              <th>Comments</th>
            </tr>
          </thead>
          <tbody>
            {data.map((x, key) => (
              <tr key={key}>
                <td>
                  {x?.date?.split(" ")[0]}
                  {" - "}
                  <span style={{ color: "#999" }}>
                    {x?.date?.split(" ")[1].replace(/(:\d{2}| [AP]M)$/, "")}
                  </span>
                </td>
                <td>{x?.name}</td>
                <td>{x?.email}</td>
                <td>{x?.phone}</td>
                <td>{x?.type}</td>
                <td>{x?.comments}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Inquiries;
