import React, { useState, useEffect } from "react";
import AbhaIcon from "../assets/images/abha-icon.png";
import Banner from "../assets/images/banner-features.png";
import { Helmet } from "react-helmet";
import fetchData from "../services/requester";

const Features = () => {
  const [headers, setHeaders] = useState();
  const [features, setData] = useState([]);
  const [active, setActive] = useState("tab1");

  const getData = () => {
    let reqURL = "features.json";
    fetchData(reqURL, null, (res) => {
      setHeaders(res || "");
      setData(res.data || []);
    });
  };

  useEffect(() => {
    getData();
    window.scrollTo(0, -1);
  }, []);

  return (
    <div className="page-inside page-features">
      <Helmet>
        <title>{headers?.title}</title>
        <meta name="keywords" content={headers?.keywords} />
        <meta name="description" content={headers?.desscription} />
      </Helmet>

      <div className="page-inside-banner">
        <div className="container">
          <h1 className="text-center">{headers?.header}</h1>
        </div>
        <img src={Banner} alt="" />
      </div>

      <div className="container">
        <p className="text-secondary text-center">{headers?.subtitle}</p>
        <div className="home-features">
          <div className="container">
            <div className="d-flex home-features-wrap home-features-icons-mobile-wrap">
              {features &&
                features.map((x, key) => (
                  <div
                    key={key}
                    onClick={() => setActive("tab" + x?.index)}
                    className={
                      "feature-box text-center p-2 mb-3 " +
                      (active === "tab" + x.index ? "active" : "")
                    }
                    title={x?.title}
                  >
                    <div className="w-100">
                      <span className="features-icon material-symbols-outlined">
                        {x.index !== 2 && x?.icon}
                        {x.index === 2 && <img src={AbhaIcon} alt="" />}
                      </span>

                      <h5>{x?.title}</h5>
                    </div>
                  </div>
                ))}
            </div>

            {features &&
              features.map(
                (x, key) =>
                  active === "tab" + x.index && (
                    <div className="row mt-5" key={key}>
                      <div className="col-lg-4 text-center mb-5">
                        <img
                          className="mobile-screen1"
                          src={x?.image}
                          alt={x?.title}
                          title={x?.title}
                        />
                      </div>
                      <div className="col-lg-8">
                        <div className="ps-5 home-features-content">
                          <div key={key}>
                            <h3 className="mb-4">{x?.title}</h3>
                            <p className="text-secondary mb-5">{x?.desc}</p>
                            <ul className="list-unstyled text-secondary">
                              {x?.data.map((y, key) => (
                                <li key={key}>
                                  <span className="check-bullet material-icons-sharp">
                                    check_circle
                                  </span>
                                  {y}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
