import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";

const HomeTestimonials = ({ testimonials }) => {
  const { data } = testimonials || [];
  return (
    <div className="home-testimonials">
      <div className="container">
        <h2 className="text-center">{testimonials?.title}</h2>
        <h5 className="mb-5">{testimonials?.subtitle}</h5>

        <Swiper
          slidesPerView={1}
          centeredSlides={false}
          slidesPerGroupSkip={1}
          grabCursor={true}
          breakpoints={{
            769: {
              slidesPerView: 3,
              slidesPerGroup: 1,
            },
          }}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          modules={[Pagination, Autoplay]}
          className="mySwiper"
        >
          {data &&
            data.map((x, key) => (
              <SwiperSlide key={key}>
                <div className="home-testimonials-wrap">
                  <div className="home-testimonials-box">
                    <p>{x?.desc}</p>
                  </div>
                  <div className="mt-4">
                    <h4>{x?.title}</h4>
                  </div>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  );
};

export default HomeTestimonials;
