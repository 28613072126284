import React, { useState, useEffect } from "react";
import Banner from "../assets/images/banner-contact.png";
import { useNavigate } from "react-router-dom";
import DownloadIOS from "../assets/images/download-ios.svg";
import DownloadPlay from "../assets/images/download-play.svg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import SM1 from "../assets/images/sm-linkedin.svg";
import SM2 from "../assets/images/sm-facebook.svg";
import SM3 from "../assets/images/sm-youtube.svg";
import SM4 from "../assets/images/sm-instagram.svg";
import SM5 from "../assets/images/sm-twitter.svg";
import fetchData from "../services/requester";
import fetchDataExt from "../services/requester-ext";

const ContactUs = (props) => {
  const page =
    window.location.pathname === "/contact-us" ? "contact" : "thanks";
  const navigate = useNavigate();
  const [headers, setHeaders] = useState();
  const [data, setData] = useState();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    comments: "",
    isSubscribed: "",
    type: "Inquiry",
  });
  const [validation, setValidation] = useState({
    name: false,
    email: false,
    phone: false,
    isSubscribed: false,
  });
  const [updateKey, setUpdateKey] = useState(1);
  const [isShowLoading, setLoading] = useState(false);

  const onChange = (e) => {
    if (e.target.name === "name") {
      formData.name = e.target.value;
    }
    if (e.target.name === "email") {
      formData.email = e.target.value;
    }
    if (e.target.name === "phone") {
      formData.phone = e.target.value;
    }
    if (e.target.name === "comments") {
      formData.comments = e.target.value;
    }
    if (e.target.name === "isSubscribed") {
      formData.isSubscribed = e.target.value;
    }
    setFormData(formData);
  };

  const handleSubmit = () => {
    setLoading(true);
    let obj = {
      path: "add-inquiry",
      inquiry: formData,
    };

    fetchDataExt(
      obj,
      (res) => {
        setLoading(false);
        navigate("/thank-you");
        window.scrollTo(0, 0);
      },
      "POST"
    );
  };

  const handleValidation = () => {
    if (!formData?.name || !formData?.email || !formData?.phone) {
      validation.name = !formData?.name ? true : false;
      validation.email = !formData?.email ? true : false;
      validation.phone = !formData?.phone ? true : false;
      setValidation(validation);
      setUpdateKey(updateKey + 1);
    } else {
      if (!emailValid(formData?.email) || !phoneValid(formData?.phone)) {
        validation.email = !emailValid(formData?.email) ? true : false;
        validation.phone = !phoneValid(formData?.phone) ? true : false;
        setValidation(validation);
        setUpdateKey(updateKey + 1);
      } else {
        handleSubmit();
      }
    }
  };

  const getData = () => {
    let reqURL = "contact.json";
    fetchData(reqURL, null, (res) => {
      setHeaders(res || "");
      setData(res.data);
    });
  };

  const emailValid = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const phoneValid = (phone) => {
    var phoneNum = phone.replace(/[^\d]/g, "");
    if (phoneNum.length > 6 && phoneNum.length < 15) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    getData();
    window.scrollTo(0, -1);
  }, []);

  return (
    <div className="page-inside page-contact">
      <Helmet>
        <title>{headers?.title}</title>
        <meta name="keywords" content={headers?.keywords} />
        <meta name="description" content={headers?.desscription} />
      </Helmet>

      <div className="page-inside-banner">
        <div className="container">
          <h1 className="text-center">{headers?.header}</h1>
        </div>
        <img src={Banner} alt={headers?.header} />
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            {page === "thanks" && (
              <div className="shadow p-5 text-center">
                <h4 className="text-primary">{data?.success}</h4>
                <p className="mt-4">
                  {data?.reachus}{" "}
                  <Link
                    className="text-primary"
                    to="mailto:support@aarogya.one"
                  >
                    support@aarogya.one
                  </Link>
                </p>
                <h5 className="text-primary mt-5">{data?.socialfollow}</h5>
                <div className="SocialIcons pt-3 d-flex justify-content-center">
                  <div className="sm-icons">
                    <Link
                      to="https://www.linkedin.com/company/aarogyaone"
                      target="_blank"
                    >
                      <img src={SM1} alt="" />
                    </Link>

                    <Link
                      to="https://www.facebook.com/aarogyaone"
                      target="_blank"
                    >
                      <img src={SM2} alt="Facebook" />
                    </Link>

                    <Link
                      to="https://www.youtube.com/@AarogyaOne"
                      target="_blank"
                    >
                      <img src={SM3} alt="" />
                    </Link>

                    <Link
                      to="https://www.instagram.com/aarogyaone"
                      target="_blank"
                    >
                      <img src={SM4} alt="" />
                    </Link>

                    <Link
                      to="https://www.twitter.com/@aarogyaone"
                      target="_blank"
                    >
                      <img src={SM5} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
            )}

            {page === "contact" && (
              <div className="contact-form">
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    {data?.name} *
                  </label>
                  <input
                    name="name"
                    id="name"
                    className="form-control"
                    onChange={onChange}
                  />

                  {validation?.name && (
                    <p className="error-red" key={updateKey}>
                      {data?.name} {data?.required}
                    </p>
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    {data?.email} *
                  </label>
                  <input
                    name="email"
                    id="email"
                    className="form-control"
                    onChange={onChange}
                  />
                  {validation?.email && (
                    <p className="error-red">
                      {data?.email} {data?.required}
                    </p>
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="phone" className="form-label">
                    {data?.phone} *
                  </label>
                  <input
                    name="phone"
                    id="phone"
                    className="form-control"
                    onChange={onChange}
                  />
                  {validation?.phone && (
                    <p className="error-red">
                      {data?.phone} {data?.required}
                    </p>
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="comments" className="form-label">
                    {data?.comments}
                  </label>
                  <textarea
                    name="comments"
                    id="comments"
                    className="form-control"
                    onChange={onChange}
                  />
                  {validation?.comments && (
                    <p className="error-red">
                      {data?.comments} {data?.required}
                    </p>
                  )}
                </div>

                <div className="form-check mt-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="isSubscribed"
                    id="isSubscribed"
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="isSubscribed">
                    {data?.subscribe}
                  </label>
                </div>

                <p className="mt-3 text-secondary" style={{ fontSize: "1rem" }}>
                  {data?.terms}{" "}
                  <Link to="/privacy-policy" className="text-primary">
                    {data?.policy}
                  </Link>
                  .
                </p>

                <button
                  className="btn btn-primary btn-lg mt-3"
                  onClick={handleValidation}
                >
                  {isShowLoading && (
                    <div className="spinner-border spinner-border-sm text-light me-2"></div>
                  )}
                  {data?.submit}
                </button>
              </div>
            )}
          </div>
          <div className="col-lg-4">
            <div className="contact-details">
              <h5 className="text-white">{data?.connect}</h5>
              <div className="contact-email mb-4">
                <span className="material-symbols-outlined">mail</span>
                <Link to="mailto:support@aarogya.one">support@aarogya.one</Link>
              </div>

              <div className="contact-call">
                <span className="material-symbols-outlined">call</span> +91
                87990 72687
              </div>
            </div>

            <div className="contact-details mt-4">
              <h5 className="text-white">{data?.follow}</h5>
              <div className="sm-icons">
                <Link
                  to="https://www.linkedin.com/company/aarogyaone"
                  target="_blank"
                >
                  <img src={SM1} alt="" />
                </Link>

                <Link to="https://www.facebook.com/aarogyaone" target="_blank">
                  <img src={SM2} alt="Facebook" />
                </Link>

                <Link to="https://www.youtube.com/@AarogyaOne" target="_blank">
                  <img src={SM3} alt="" />
                </Link>

                <Link to="https://www.instagram.com/aarogyaone" target="_blank">
                  <img src={SM4} alt="" />
                </Link>

                <Link to="https://www.twitter.com/@aarogyaone" target="_blank">
                  <img src={SM5} alt="" />
                </Link>
              </div>
            </div>

            <div className="mt-4 text-center d-flex justify-content-center download-buttons">
              <Link
                className="btn"
                target="_blank"
                to="https://play.google.com/store/apps/details?id=com.jdeanzhealthtech.aarogyaone&pcampaignid=web_share/"
              >
                <img src={DownloadPlay} style={{ height: "56px" }} alt="" />
              </Link>
              <Link
                className="btn"
                target="_blank"
                to="https://apps.apple.com/in/app/aarogyaone/id6471126953/"
              >
                <img src={DownloadIOS} style={{ height: "56px" }} alt="" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
