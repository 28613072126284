import React, { useState, useEffect } from "react";
import A1Icon from "../assets/images/a1-icon.png";
import AbhaIcon from "../assets/images/abha-icon.png";
import fetchData from "../services/requester";
import fetchDataExt from "../services/requester-ext";

const Downloads = (props) => {
  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    type: "App Request",
    comments: "",
    isSubscribed: "",
  });
  const [validation, setValidation] = useState({
    name: false,
    email: false,
    phone: false,
  });
  const [updateKey, setUpdateKey] = useState(1);
  const [isShowLoading, setLoading] = useState(false);
  const [isSubmited, setSubmited] = useState(false);
  const [features, setFeatures] = useState([]);

  const onChange = (e) => {
    if (e.target.name === "name") {
      data.name = e.target.value;
    }
    if (e.target.name === "email") {
      data.email = e.target.value;
    }
    if (e.target.name === "phone") {
      data.phone = e.target.value;
    }
    setData(data);
  };

  const handleSubmit = () => {
    setLoading(true);
    let obj = {
      path: "add-inquiry",
      inquiry: data,
    };
    fetchDataExt(
      obj,
      (res) => {
        setSubmited(true);
      },
      "POST"
    );
  };

  const handleValidation = () => {
    if (!data?.name || !data?.email || !data?.phone) {
      validation.name = !data?.name ? true : false;
      validation.email = !data?.email ? true : false;
      validation.phone = !data?.phone ? true : false;
      setValidation(validation);
      setUpdateKey(updateKey + 1);
    } else {
      if (!emailValid(data?.email) || !phoneValid(data?.phone)) {
        validation.email = !emailValid(data?.email) ? true : false;
        validation.phone = !phoneValid(data?.phone) ? true : false;
        setValidation(validation);
        setUpdateKey(updateKey + 1);
      } else {
        handleSubmit();
      }
    }
  };

  const emailValid = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const phoneValid = (phone) => {
    var phoneNum = phone.replace(/[^\d]/g, "");
    if (phoneNum.length > 6 && phoneNum.length < 15) {
      return true;
    } else {
      return false;
    }
  };

  const getData = () => {
    let reqURL = "home.json";
    fetchData(reqURL, null, (res) => {
      setFeatures(res?.features.data || []);
    });
  };

  const handleReset = () => {
    setLoading(false);
    setSubmited(false);
    setData({
      name: "",
      email: "",
      phone: "",
      type: "App Request",
      comments: "",
      isSubscribed: "",
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div
      className="downloads-popup-bg animate-downloads-popup-bg"
      onClick={props.handleDownloads}
    >
      <div className="downloads-popup" onClick={(e) => e.stopPropagation()}>
        <div className="mb-5">
          <h4 className="mb-4 d-flex align-items-center">
            <img
              src={A1Icon}
              alt="Download Aarogya1 App"
              style={{ maxHeight: "42px" }}
              className="me-3"
            />
            {props?.download}
          </h4>

          <button
            className="btn downloads-popup-close"
            onClick={props.handleDownloads}
          >
            <span className="material-symbols-outlined">close</span>
          </button>
        </div>

        {isSubmited && (
          <React.Fragment>
            <h5 className="fw-normal mb-3 text-primary">
              {props?.downloadinfo}
            </h5>
            <h6 className="fw-normal mb-4">
              {props?.downloadnotget}{" "}
              <span
                className="text-primary"
                style={{ cursor: "pointer" }}
                onClick={handleReset}
              >
                {props?.downloadtry}
              </span>
            </h6>
          </React.Fragment>
        )}

        {!isSubmited && (
          <div className="row">
            <div className="col-lg-12">
              <h6 className="fw-normal mb-3">{props?.downloadinfo}</h6>
            </div>
            <div className="col-lg-3">
              <input
                name="name"
                id="name"
                className="form-control"
                onChange={onChange}
                placeholder={props?.downloadform?.name + " *"}
              />

              {validation?.name && (
                <p className="error-red" key={updateKey}>
                  {props?.downloadform?.name} {props?.downloadform?.required}
                </p>
              )}
            </div>

            <div className="col-lg-3">
              <input
                name="phone"
                id="phone"
                className="form-control"
                onChange={onChange}
                placeholder={props?.downloadform?.phone + " *"}
              />
              {validation?.phone && (
                <p className="error-red">
                  {props?.downloadform?.phone} {props?.downloadform?.required}
                </p>
              )}
            </div>

            <div className="col-lg-4">
              <input
                name="email"
                id="email"
                className="form-control"
                onChange={onChange}
                placeholder={props?.downloadform?.email + " *"}
              />
              {validation?.email && (
                <p className="error-red">
                  {props?.downloadform?.email} {props?.downloadform?.required}
                </p>
              )}
            </div>

            <div className="col-lg-2">
              <button className="btn btn-primary" onClick={handleValidation}>
                {isShowLoading && (
                  <div className="spinner-border spinner-border-sm text-light me-2"></div>
                )}
                {props?.downloadform?.get}
              </button>
            </div>
          </div>
        )}

        <div className="mt-5 home-features-mobile-download">
          <h5 className="mb-4">{props?.downloadfeature}</h5>

          <div className="home-features">
            <div className="row">
              {features &&
                features.map(
                  (x, key) =>
                    key < 8 && (
                      <div className="col-lg-3" key={key}>
                        <div
                          className="feature-box text-center p-2 mb-3 d-flex"
                          title={x?.title}
                        >
                          <span className="features-icon material-symbols-outlined">
                            {x.index !== 2 && x?.icon}
                            {x.index === 2 && <img src={AbhaIcon} alt="" />}
                          </span>

                          <h5 className="text-secondary">{x?.title}</h5>
                        </div>
                      </div>
                    )
                )}
            </div>
          </div>
        </div>
      </div>
      ;
    </div>
  );
};

export default Downloads;
