import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";

const HomeVblogs = ({ vblogs }) => {
  const { data } = vblogs || [];
  return (
    <div className="home-vblogs">
      <div className="container">
        <h2 className="text-center">{vblogs?.title}</h2>
        <h5 className="mb-5">{vblogs?.subtitle}</h5>

        <Swiper
          slidesPerView={1}
          centeredSlides={false}
          slidesPerGroupSkip={1}
          grabCursor={true}
          breakpoints={{
            769: {
              slidesPerView: 3,
              slidesPerGroup: 1,
            },
          }}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          modules={[Pagination, Autoplay]}
          className="mySwiper"
        >
          {data &&
            data.map((x, key) => (
              <SwiperSlide key={key}>
                <div className="home-vblogs-wrap">
                  <div className="home-vblogs-box">
                    <iframe
                      width="100%"
                      height="212"
                      src={x?.src}
                      key={x?.index}
                      allowFullScreen="allowFullScreen"
                      title={x?.title}
                      frameBorder="0"
                    ></iframe>
                  </div>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  );
};

export default HomeVblogs;
