import React, { useState } from "react";
import { Link } from "react-router-dom";

const GDPR = (props) => {
  const [active, setActive] = useState(
    localStorage.getItem("gdpr-popup") ? "accepted" : ""
  );

  const handlePopup = () => {
    localStorage.setItem("gdpr-popup", "accepted");
    setActive("accepted");
  };

  return (
    <React.Fragment>
      {active !== "accepted" && (
        <div className="gdpr-popup p-4" key={active}>
          <h5 className="text-primary">{props?.gdprtitle}</h5>
          <p className="small">
            {props?.gdpr}{" "}
            <Link to="/privacy-policy" className="text-primary">
              {props?.privacy}
            </Link>
          </p>
          <button
            className="btn btn-primary btn-sm"
            onClick={() => handlePopup()}
          >
            {props?.accept}
          </button>
        </div>
      )}
    </React.Fragment>
  );
};

export default GDPR;
