import React, { useState, useEffect } from "react";
import Banner from "../assets/images/banner-about.png";
import parse from "html-react-parser";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import fetchData from "../services/requester";

const AboutUs = () => {
  const [headers, setHeaders] = useState();
  const [data, setData] = useState([]);
  const [team, setTeam] = useState();

  const getData = () => {
    let reqURL = "about.json";
    fetchData(reqURL, null, (res) => {
      setHeaders(res || "");
      setData(res.data || []);
      setTeam(res.data.find((x) => x.team).team);
    });
  };

  useEffect(() => {
    getData();
    window.scrollTo(0, -1);
  }, []);

  return (
    <div className="page-inside page-about">
      <Helmet>
        <title>{headers?.title}</title>
        <meta name="keywords" content={headers?.keywords} />
        <meta name="description" content={headers?.desscription} />
      </Helmet>

      <div className="page-inside-banner">
        <div className="container">
          <h1 className="text-center">{headers?.header}</h1>
        </div>
        <img src={Banner} alt={headers?.header} />
      </div>

      <div className="container">
        <p className="text-center text-secondary">{headers?.subtitle}</p>

        {data &&
          data.map((x, key) => (
            <React.Fragment key={key}>
              {x?.title && (
                <h2 className="text-primary text-center mt-5 mb-3">
                  {x?.title}
                </h2>
              )}

              {x?.subtitle && (
                <h5 className="text-center mb-5">{x?.subtitle}</h5>
              )}

              {x?.paragraphs &&
                x?.paragraphs.map((y, key) => (
                  <p className="text-secondary" key={key}>
                    {parse(y)}
                  </p>
                ))}

              {x?.lists && (
                <ul className="text-secondary mt-4">
                  {x?.lists.map((z, key) => (
                    <li key={key}>{parse(z)}</li>
                  ))}
                </ul>
              )}

              {x?.numlists && (
                <ol className="text-secondary mt-4">
                  {x?.numlists.map((w, key) => (
                    <li key={key}>{parse(w)}</li>
                  ))}
                </ol>
              )}

              {x?.note && <p className="text-secondary">{parse(x?.note)}</p>}

              {<div className="mt-3">&nbsp;</div>}
            </React.Fragment>
          ))}

        <div className="about-team">
          <h2 className="text-primary text-center mb-3">
            {team && team?.title}
          </h2>

          <h5 className="mb-5 text-center" style={{ lineHeight: "2rem" }}>
            {team && team?.subtitle}
          </h5>

          <div className="row">
            {team &&
              team?.members.map((x, key) => (
                <React.Fragment key={key}>
                  <div className={key < 3 ? "col-lg-4 mb-4" : "col-lg-3 mb-4"}>
                    <div className="shadow1" style={{ height: "100%" }}>
                      {x?.image && (
                        <img
                          src={x?.image}
                          alt="x?.name"
                          className="w-100 rounded"
                        />
                      )}

                      {(x?.name || x?.designation) && (
                        <div className="p-4 text-center">
                          {x?.name && <h5 className="mb-0">{x?.name}</h5>}
                          {x?.designation && (
                            <h6 className="text-secondary small mb-0 mt-2">
                              {x?.designation}
                            </h6>
                          )}
                        </div>
                      )}

                      {x?.advisory && (
                        <div className="p-4">
                          <h5 className="text-center mb-4 text-primary">
                            {x?.title}
                          </h5>
                          <ul className="about-team-advisory">
                            {x?.advisory.map((y, key) => (
                              <li key={key}>{y}</li>
                            ))}
                          </ul>
                        </div>
                      )}

                      {x?.apply && (
                        <div
                          className="d-flex align-items-center justify-content-center rounded"
                          style={{ height: "100%" }}
                        >
                          <Link
                            to="mailto:connect@jdeanzhealthtech.com"
                            className="btn btn-lg btn-primary"
                          >
                            {x?.apply}
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </React.Fragment>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
